/*
 * @Author: your name
 * @Date: 2021-06-17 23:21:18
 * @LastEditTime: 2022-05-17 08:59:13
 * @LastEditors: slw 3289360550@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/reducers/index.js
 */

//  对reducers 进行汇总
import { combineReducers } from "redux";
import updateTime from "./answer";
import updateWordTime from "./word";
import examItem from "./examItem";
import courseInfo from "./courseInfo";
import nowExamIndex from "./nowExamIndex";
import answerStates from "./answerStates";
import rewriteState from "./rewriteState";
import isShowAnswer from "./isShowAnswer";
import errorExamState from "./errorExamState";
import subjectId from "./subjectId";
import backState from "./backState";
import studyWords from "./studyWords"
import padHeader from "./padHeader"

const allReducer = combineReducers({
  updateWordTime,
  updateTime,
  examItem,
  courseInfo,
  nowExamIndex,
  answerStates,
  isShowAnswer,
  errorExamState,
  subjectId,
  backState,
  rewriteState, // 是否是订正
  studyWords,
  padHeader
});

export default allReducer;
