import { SET_STUDY_RECORDER, CLEAR_STUDY_RECORDER, SET_STUDY_STYLE } from "../constant";

const initState = {
  answeringResults: [], // 检测的记录
  style: 1, // 学习样式 
  model: 0 //检测模式 0 普通 1 困难
};

export default function studyWordsReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case SET_STUDY_RECORDER:
      return {
        ...initState,
        answeringResults: preState.answeringResults.concat(data)
      }
    case CLEAR_STUDY_RECORDER:
      initState.answeringResults = [];
      return {
        ...initState,
        answeringResults: []
      }
    case SET_STUDY_STYLE:
      initState.style = data.style
      initState.model = data.model
      return {
        ...initState,
        ...data
      }
    default:
      return preState;
  }
}
