/*
 * @Author: your name
 * @Date: 2021-06-17 14:54:28
 * @LastEditTime: 2021-07-26 14:55:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/reducers/count.js
 */

import { UPDATEEXAM } from "../constant";

const initState = {};
export default function examReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATEEXAM:
      return data;
    default:
      return preState;
  }
}
