/*
 * @Author: your name
 * @Date: 2021-08-09 09:19:26
 * @LastEditTime: 2021-08-09 09:20:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/redux/reducers/errorExamState.js
 */
import { UPDATEERROREXAMSTATE } from "../constant";

const initState = false;
export default function rightAnswerStateReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATEERROREXAMSTATE:
      return data;
    default:
      return preState;
  }
}
