/*
 * @Author: your name
 * @Date: 2021-06-17 14:54:28
 * @LastEditTime: 2021-08-05 13:36:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/reducers/count.js
 */

import { UPDATETIME, INCREMENTTIME } from "../constant";

const initState = 0;
export default function timerReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATETIME:
      return data;
    case INCREMENTTIME:
      return preState + data;
    default:
      return preState;
  }
}
