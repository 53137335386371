/*
 * @Author: your name
 * @Date: 2021-06-24 16:57:05
 * @LastEditTime: 2022-08-19 11:41:30
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/index.js
 */
import React, { useEffect } from "react";

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "remixicon/fonts/remixicon.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { HashRouter } from "react-router-dom";

import { initTheme } from "./assets/js/theme";
// /Mobi|Android|iPhone/i.test(navigator.userAgent) &&
// / var(--main-fz)   / 12
if (navigator.userAgent.indexOf("sdzn-android") > -1) {
  // 当前设备是移动设备,需要修改字体大小
  initTheme("smallTheme"); // 12
} else if (window.screen.height > 1000) {
  initTheme("smallTheme"); // 12
} else {
  initTheme("bigTheme"); // 8
}

// 获取当前可视区域的高度
let viewHeight = document.documentElement.clientHeight
window.onresize = () => { // 在页面大小发生变化时调用
  // 把获取到的高度赋值给根div
  document.getElementById('root').style.height = viewHeight + 'px'
}

ReactDOM.render(
  // <React.StrictMode>
  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
