/*
 * @Author: your name
 * @Date: 2021-08-09 08:51:47
 * @LastEditTime: 2021-08-09 08:52:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/redux/reducers/isShowAnswer.js
 */
import { UPDATEANSWERSHOWSTATE } from "../constant";

const initState = true;
export default function rightAnswerStateReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATEANSWERSHOWSTATE:
      return data;
    default:
      return preState;
  }
}
