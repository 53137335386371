/*
 * @Author: your name
 * @Date: 2021-06-24 16:57:05
 * @LastEditTime: 2022-09-15 17:30:53
 * @LastEditTime: 2021-08-06 10:54:32
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /antd-demo/src/App.js
 */
import React, { Suspense } from "react";
import { Route, Switch, HashRouter as Router } from "react-router-dom";
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN';
import "./App.less";

const Login = React.lazy(() => import("./pages/login"));
const IndexPage = React.lazy(() => import("./pages/indexPage"));
const WordPk = React.lazy(() => import("./pages/wordPK"));
const WordPkDetails = React.lazy(() => import("./pages/PKdetails"));
const AnswerWrapper = React.lazy(() => import("./pages/studentAnswer"));
const PracticeReport = React.lazy(() => import("./pages/practiceReport"));
const ListQuestionSheet = React.lazy(() => import("./pages/listQuestionSheet"));
const MyCollect = React.lazy(() => import("./pages/myCollect"));
const CooperativeInquiry = React.lazy(() => import("./pages/cooperativeInquiry"));
const ErrorBook = React.lazy(() => import("./pages/errorBook"));
const KeyboardPage = React.lazy(() => import("./pages/keyboardPage"));
const GroupsScoreRank = React.lazy(() => import("./pages/groupsScoreRank"));
const ElectronicBook = React.lazy(() => import("./pages/electronicBook"));
const ElectronicPdfBook = React.lazy(() => import("./pages/electronicPdfBook"));
const LiveList = React.lazy(() => import("./pages/liveList"));
const ShopCart = React.lazy(() => import("./pages/liveList/components/shopCart"));
const VideoPlayback = React.lazy(() => import("./pages/liveList/components/videoPlayback"));
const MyCourse = React.lazy(() => import("./pages/liveList/components/myCourse"));
const WordStatistics = React.lazy(() => import("./pages/wordStatistics"));
const WordsRecite = React.lazy(() => import("./pages/wordsRecite"));
const Test = React.lazy(() => import("./pages/test"));
const PktIndexPage = React.lazy(() => import("./pages/pktIndexPage"));
const Glossary = React.lazy(() => import("./pages/glossary"));
const AutonomyPractice = React.lazy(() => import("./pages/autonomyPractice"));
const AutonomyAnswer = React.lazy(() => import("./pages/autonomyAnswer"));
const AutonomyPracticeReport = React.lazy(() => import("./pages/autonomyPracticeReport"));
const AutonomyCheck = React.lazy(() => import("./pages/autonomyCheck"));
const MyResult = React.lazy(() => import("./pages/myResult"));
const StudentAnalysis = React.lazy(() => import("./pages/studentAnalysis"));
const Japanese = React.lazy(() => import("./pages/japanese"));
const MistakesBooks = React.lazy(() => import("./pages/mistakesBooks"));
const MistakesBank = React.lazy(() => import("./pages/mistakesBank"));
const MistakesRearrange = React.lazy(() => import("./pages/mistakesRearrange"));
const LookTestPaper = React.lazy(() => import("./pages/lookTestPaper"));
const MemorizeWords = React.lazy(() => import("./pages/memorizeWords"));
const StudyWordsWay = React.lazy(() => import("./pages/studyWordsWay"));
const WordsChapterExericse = React.lazy(() => import("./pages/wordsChapterExercise"));
const WordDetection = React.lazy(() => import("./pages/wordDetection"));
const WordLearningRecord = React.lazy(() => import("./pages/wordLearningRecord"));
const WordEnglishCorner = React.lazy(() => import("./pages/wordEnglishCorner"));
const WordReview = React.lazy(() => import("./pages/wordReview"));
const IterationInfor = React.lazy(() => import("./pages/IterationInfor"));
const EarlyReading = React.lazy(() => import("./pages/earlyReading"));
const Morality = React.lazy(() => import("./pages/morality"));
const MoralityEnter = React.lazy(() => import("./pages/moralityEnter"));
const MoralityEnterGPA = React.lazy(() => import("./pages/moralityEnter_2phase"));
const AiChat = React.lazy(() => import("./pages/aiChat"));

const App = () => (

  <ConfigProvider locale={zhCN}>
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route component={Login} exact path="/"></Route>
          <Route component={Login} path="/login"></Route>
          <Route component={IndexPage} path="/indexPage"></Route>
          <Route component={AnswerWrapper} path="/answer"></Route>
          <Route component={WordsRecite} path="/wordsRecite"></Route>
          <Route component={PracticeReport} path="/practiceReport"></Route>
          <Route component={KeyboardPage} path="/KeyboardPage"></Route>
          <Route component={ListQuestionSheet} path="/listQuestionSheet"></Route>
          <Route component={MyCollect} path="/myCollect"></Route>
          <Route component={CooperativeInquiry} path="/cooperativeInquiry"></Route>
          <Route component={ErrorBook} path="/errorBook"></Route>
          <Route component={GroupsScoreRank} path="/GroupsScoreRank"></Route>
          <Route component={LiveList} path="/liveList"></Route>
          <Route component={ElectronicBook} path="/electronicBook"></Route>
          <Route component={ElectronicPdfBook} path="/electronicPdfBook"></Route>
          <Route component={ShopCart} path="/shopCart"></Route>
          <Route component={MyCourse} path="/myCourse"></Route>
          <Route component={VideoPlayback} path="/videoPlayback"></Route>
          <Route component={WordPk} path="/wordPk"></Route>
          <Route component={WordPkDetails} path="/wordPkDetails"></Route>
          <Route component={WordStatistics} path="/wordStatistics"></Route>
          <Route component={Test} path="/test"></Route>
          <Route component={PktIndexPage} path="/pktIndexPage"></Route>
          <Route component={Glossary} path="/glossary"></Route>
          <Route component={AutonomyPractice} path="/autonomyPractice"></Route>
          <Route component={AutonomyAnswer} path="/autonomyAnswer"></Route>
          <Route component={AutonomyPracticeReport} path="/autonomyPracticeReport"></Route>
          <Route component={AutonomyCheck} path="/autonomyCheck"></Route>
          <Route component={MyResult} path="/myResult"></Route>
          <Route component={StudentAnalysis} path="/analysis"></Route>
          <Route component={Japanese} path="/japanese"></Route>
          <Route component={MistakesBooks} path="/mistakesBooks"></Route>
          <Route component={MistakesBank} path="/mistakesBank"></Route>
          <Route component={MistakesRearrange} path="/mistakesRearrange"></Route>
          <Route component={LookTestPaper} path="/lookTestPaper"></Route>
          <Route component={MemorizeWords} path="/memorizeWords"></Route>
          <Route component={StudyWordsWay} path="/studyWordsWay"></Route>
          <Route component={WordsChapterExericse} path="/wordsChapterExerise"></Route>
          <Route component={WordDetection} path="/wordDetection"></Route>
          <Route component={WordLearningRecord} path="/wordLearningRecord"></Route>
          <Route component={WordEnglishCorner} path="/wordEnglishCorner"></Route>
          <Route component={WordReview} path="/WordReview"></Route>
          <Route component={IterationInfor} path="/IterationInfor"></Route>
          <Route component={EarlyReading} path="/earlyReading"></Route>
          <Route component={Morality} path="/morality"></Route>
          <Route component={MoralityEnter} path="/moralityEnter"></Route>
          <Route component={MoralityEnterGPA} path="/moralityEnterGPA"></Route>
          <Route component={AiChat} path="/AIchat"></Route>
        </Switch>
      </Suspense>
    </Router>
  </ConfigProvider>
);

export default App;
