/*
 * @Author: your name
 * @Date: 2022-03-25 13:53:10
 * @LastEditTime: 2022-03-25 13:53:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\redux\reducers\word.js
 */

import { UPDATEWORDTIME, INCREMENTTIME } from "../constant";

const initState = 0;
export default function timerReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATEWORDTIME:
      return data;
    case INCREMENTTIME:
      return preState + data;
    default:
      return preState;
  }
}
